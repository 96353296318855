/* eslint-disable react-hooks/rules-of-hooks */
import React, { type FC, useRef } from 'react';
import classnames from 'classnames';
import { useApp } from 'bb/app';
import { Link } from 'bb/app/nav';
import { useRouter } from 'bb/app/router';
import { useFocusElement } from 'bb/common/hooks';
import { useFeature } from 'bb/config/features';
import {
    type Badge as BadgeType,
    type BookLightResponse
} from 'bb/discovery/types';
import { getDiscoveryUrlSlug } from 'bb/discovery/utils';
import { Text, useTranslation } from 'bb/i18n';
import useAllyCardTitle from 'bb/i18n/useAllyCardTitle';
import {
    type ECommerceViewItem,
    mapBookLightResponseToECommerceItems,
    useECommerceTracking
} from 'bb/tracker/hooks/useECommerceTracking';
import { Card, CardHeader, StarIcon, ImageIcon, type SvgName } from 'bb/ui';
import { Badge } from 'bb/ui';
import { NumberBadge } from 'bb/ui/Badge/NumberBadge';
import css from './bookCard.module.scss';
import { BookCardBadge } from './BookCardBadge';
import { BookCardImage } from './BookCardImage';

interface BookCardProps {
    showSeriesPartNumber?: boolean;
    book: BookLightResponse;
    focusOnMount?: boolean;
    ownedAudiobook?: boolean;
    ownedEbook?: boolean;
    /**
     * If the bookList `renderType` equals `NumberedList`
     * a number will be passed to the card.
     *
     * If passed, no badges are shown.
     */
    bookTopListNumber?: number;
}

export const getBadges = (badgeList: BadgeType[]) => {
    const defaultBadgeLists = { toplist: [], default: [] };

    const { toplist: topListbadges, default: defaultBadges } = Array.isArray(
        badgeList
    )
        ? badgeList.reduce<{ toplist: BadgeType[]; default: BadgeType[] }>(
              (memo, x) => {
                  if (x.type === 'toplist' || x.type === 'default') {
                      memo[x.type].push(x);
                  }
                  return memo;
              },
              defaultBadgeLists
          )
        : defaultBadgeLists;

    const primaryBadge = topListbadges?.[0] || defaultBadges?.[0];
    const secondaryBadge = topListbadges.length
        ? defaultBadges?.[0]
        : defaultBadges?.[1];
    return { primaryBadge, secondaryBadge };
};

const getTrackingData = (book: BookCardProps['book']): ECommerceViewItem => {
    const currency =
        book.audiobooksinglesale?.currency ||
        book.ebooksinglesale?.currency ||
        '';
    const ecommerceItems = mapBookLightResponseToECommerceItems({ book });

    const totalPrice = ecommerceItems?.reduce(
        (total, item) => total + (item?.price || 0),
        0
    );

    return {
        currency,
        value: totalPrice || 0,
        items: ecommerceItems || []
    };
};

// eslint-disable-next-line complexity
export const BookCard: FC<BookCardProps> = ({
    book,
    showSeriesPartNumber = false,
    focusOnMount,
    ownedAudiobook,
    ownedEbook,
    bookTopListNumber
}) => {
    const { routes } = useRouter();
    const tracker = useECommerceTracking();
    const { t } = useTranslation(['bookCard', 'book']);
    const { locale } = useApp();

    const urlSlug = getDiscoveryUrlSlug({
        title: book.title,
        id: book.id.toString()
    });
    const linkRef = useRef<HTMLAnchorElement>(null);
    useFocusElement(Boolean(focusOnMount), linkRef);

    const isAudioDrama = book.contenttypetags?.includes('audiodrama');

    const isSingleSale = book.audiobooksinglesale || book.ebooksinglesale;

    const [primaryBadge, secondaryBadge] = book.topbadges || [];

    const allyText = useAllyCardTitle(
        book,
        t,
        locale,
        bookTopListNumber,
        primaryBadge,
        secondaryBadge,
        ownedAudiobook,
        ownedEbook
    );
    const showBadges = useFeature('show-badges');

    return (
        <Card className={css.card}>
            <Link
                ref={linkRef}
                route={routes.book}
                query={{
                    bookId: urlSlug
                }}
                title={allyText}
                onClick={() =>
                    isSingleSale &&
                    tracker('select_item', { ecommerce: getTrackingData(book) })
                }
            >
                <CardHeader
                    className={css.header}
                    left={
                        <div className={classnames(css.headerItem, css.rating)}>
                            {book.grade > 0 && (
                                <>
                                    <StarIcon
                                        color="primary-white"
                                        size="small"
                                        inline
                                    />
                                    <span>{book.grade}</span>
                                </>
                            )}
                        </div>
                    }
                    right={
                        <div className={classnames(css.headerItem, css.icons)}>
                            {isAudioDrama && (
                                <ImageIcon
                                    svg="audiodrama"
                                    size="small"
                                    inline
                                    invert
                                />
                            )}
                            {(book.audio || book.audiobookisbn) &&
                                (book.audiobooksinglesale && !ownedAudiobook ? (
                                    <ImageIcon
                                        svg="locked-audiobook"
                                        size="small"
                                        inline
                                    />
                                ) : (
                                    <ImageIcon
                                        svg="headphones"
                                        size="small"
                                        inline
                                        invert
                                    />
                                ))}
                            {(book.ebook || book.ebookisbn) &&
                                (book.ebooksinglesale && !ownedEbook ? (
                                    <ImageIcon
                                        svg="locked-ebook"
                                        size="small"
                                        inline
                                    />
                                ) : (
                                    <ImageIcon
                                        svg="glasses"
                                        size="small"
                                        inline
                                        invert
                                    />
                                ))}

                            {book.series && (
                                <ImageIcon
                                    svg="series"
                                    size="small"
                                    inline
                                    invert
                                />
                            )}
                            {showSeriesPartNumber && book.series && (
                                <span>{book.series.partnumber}</span>
                            )}
                        </div>
                    }
                />

                <div className={css.cover} aria-hidden="true">
                    {bookTopListNumber && (
                        <BookCardBadge variant="topList">
                            <NumberBadge number={bookTopListNumber} />
                        </BookCardBadge>
                    )}
                    {primaryBadge && showBadges && !bookTopListNumber && (
                        <BookCardBadge variant="primary">
                            <Badge
                                translationKey={primaryBadge.translationKey}
                                icon={primaryBadge.icon as SvgName}
                            />
                        </BookCardBadge>
                    )}
                    {secondaryBadge && showBadges && (
                        <BookCardBadge variant="secondary">
                            <Badge
                                translationKey={secondaryBadge.translationKey}
                            />
                        </BookCardBadge>
                    )}
                    <BookCardImage source={book.image} title={book.title} />
                </div>
                <div className={css.footer} aria-hidden="true">
                    <div className={css.footerText}>
                        <Text className={css.ellipsis}>{book.title}</Text>
                        <Text className={css.ellipsis}>- {book.author}</Text>
                    </div>
                    <div className={css.overlay} />
                </div>
            </Link>
        </Card>
    );
};
